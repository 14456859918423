<template>
    <div>
    <v-card class="mt-2" dark max-width="400" elevation="0">
        <v-list color="#434242">
            <v-list-item>

                    <v-icon v-if="orderItem.orderStatus == 'delivered' " size="30" class="mr-3"> $boxActiveIcon </v-icon>
                    <v-icon v-if="orderItem.orderStatus == 'cancelled' " size="30" class="mr-3"> $boxInactiveIcon </v-icon>

                <v-list-item-content>
                    <v-list-item-title class="seriesNo">#{{orderItem.orderSeriesNo}}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-content class="text-right">
                    <span v-if="orderItem.orderStatus == 'delivered' " class="orderStatus"><v-chip small color="#4B9C6D">Delivered</v-chip></span>
                    <span v-if="orderItem.orderStatus == 'cancelled' " class="orderStatus"><v-chip small color="#E14B4B">Cancelled</v-chip></span>
                </v-list-item-content>

            </v-list-item>
            <v-divider class="mt-4 ml-5 mr-5 dividerLine"></v-divider>
            <div class="ml-5 mt-3 mb-3">
                <div class="mt-2"><span class="listTitle">Order No.</span>  <br><span class="listContent">{{orderItem.orderSeriesNo}}</span>  <br> </div>
                <div class="mt-2"><span class="listTitle">Order Type.</span>  <br><span class="capitaliseText">{{orderItem.orderType}}</span>  <br> </div>
                <div class="mt-2"><span class="listTitle">Order Date</span>  <br><span class="listContent">{{moment(orderItem.orderDate).format("dddd, MMMM Do YYYY, h:mm a")}}</span>  <br></div>
                <div class="mt-2"><span class="listTitle">Order Source</span>  <br><span class="orderSource">{{orderItem.orderSource}}</span>  <br> </div>
                <div v-if="orderItem.orderType == 'regular' " class="mt-2"><span class="listTitle">Payment</span>  <br><v-chip small class="text-capitalize" :color="getPaymentColor(orderItem.orderPaymentDetails.status)">{{orderItem.orderPaymentDetails.status.replace('_',' ')}}</v-chip><br></div>
                <div class="mt-2"><span class="listTitle">Order Items</span>  <br><span class="listContent">{{orderItem.cartItemsList}}</span> <br> </div>
                <div class="mt-2"><span class="listTitle">Items Count</span>  <br><span class="listContent">{{orderItem.cartItemsCount}}</span>  <br> </div>
                <div class="mt-2"><span class="listTitle">Bill Amount</span>  <br><span class="listContent">{{orderItem.orderBillAmount}}</span> <br> </div>
                <div class="mt-2"><span class="listTitle">Order Note</span>  <br><span class="listContent">{{orderItem.orderNote}}</span> <br> </div>
            </div>

        </v-list>
    </v-card>
    </div>
</template>
<script>
import moment from 'moment'
  export default {
    name: 'ViewPastOrderItem',
    props: {
     orderItem: Object
    },
    data: () => ({
        moment: moment,
    }),
    methods: {
        updateStatus (status) {
            const updateOrder = {
                status: status,
                orderId: this.orderItem.orderId
            }
            this.$emit('update-status', updateOrder)
        },
        getPaymentColor(paymentStatus) {
            if (paymentStatus == 'paid') {
                return "#4B9C6D"
            } else if (paymentStatus == 'not_paid') {
                return "#E14B4B"
            }
        }
    },
  }
</script>
<style scoped>
.dividerLine{
color: rgba(255, 255, 255, 0.5);
}
.capitaliseText {
font-family: Lato;
font-style: regular;
font-weight: 500;
font-size: 14px;
line-height: 0px;
color: #ffffff;
text-transform: capitalize;
}
.seriesNo{
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 18px;
color: #FFFFFF;
}
.listTitle {
font-family: Lato;
font-style: regular;
font-weight: 700;
font-size: 14px;
line-height: 0px;
color: #ffffff;
}
.listContent {
margin-top: 20px;
font-family: Lato;
font-style: regular;
font-weight: 500;
font-size: 14px;
line-height: 0px;
color: #ffffff;
}
.orderSource {
margin-top: 20px;
font-family: Lato;
font-style: regular;
font-weight: 500;
font-size: 14px;
line-height: 0px;
color: #ffffff;
text-transform: capitalize;
}
.houseNo {
font-family: Montserrat;
font-style: normal;
font-weight: 900;
font-size: 24px;
color: #FFFFFF;
}

.orderStatus {
font-family: Montserrat;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #FFFFFF;
}

.addToBasketBtn {
text-transform: unset !important;
background: #4B9C6D;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 29px;
color: #FFFFFF;
}
.cancelBtn {
text-transform: unset !important;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 29px;
color: #E14B4B;
}
</style>
