<template>   
    <div>
    <CircularProgress v-if="loader"/>

    <div class="mt-16" v-if="!ordersAvailable" >
      <v-col cols="12">
          <v-card class="d-flex justify-center mr-2 mt-16" color="#373535" dark elevation="0">
              <v-list color="#373535">
              <span class="d-flex justify-center">
              <v-icon class="ml-2 mt-8" size="90"> $notAvailableIcon </v-icon>
              </span>
              <span class="d-flex justify-center mt-5 cardTitle">There are no past orders available.</span>
              </v-list>
          </v-card>
      </v-col>
      </div>
        
    <div class="ml-4 mr-4 mt-4 mb-2" v-if="ordersAvailable">
      <div :key="orderItem._id" v-for="orderItem in ordersList">
       <ViewPastOrderItem :key="orderItem._id" :orderItem="orderItem" />
      </div>
    </div>
    </div>
</template>
<script>
import orderServices from '@/services/orderServices'
import ViewPastOrderItem from './ViewPastOrderItem.vue'
import CircularProgress from '../../../loaders/CircularProgress.vue'
  export default {
    name: 'ViewPastOrdersMain',
    components: {
       ViewPastOrderItem,
       CircularProgress 
    },
    data () {
        return {
            loader: false,
            ordersList: '',
            ordersAvailable: false
        }
    },
    methods: {
    },
    async mounted () {
        this.loader = true
        const getAllPastOrders = (await orderServices.getCustomerPastOrders(this.$route.params.customerId)).data;
        if (getAllPastOrders.pastOrdersList.length !=0) {
          this.ordersList = getAllPastOrders.pastOrdersList
          this.ordersAvailable = true
        } else {
          this.ordersList = ''
          this.ordersAvailable = false
        }
        this.loader = false
    }
  }
</script>
<style scoped>
.cardTitle{
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 12px;
color: #C7C6C6;
}
.catalog_title {
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 24px;
line-height: 46px;
color: rgba(255, 255, 255, 0.8);
}
.notAvailableText {
font-family: Lato;
font-style: regular;
font-weight: 700;
font-size: 14px;
color: #9B9A9A;
}
</style>