<template>
    <div>
        <v-row no-gutters>
         <v-col class="mt-6 ml-2" cols="2">
           <v-icon size="60" v-if="this.customerGender == 'Female'"> $womanIcon </v-icon>
            <v-icon size="60" v-if="this.customerGender == 'Male'"> $manIcon </v-icon>
         </v-col> 
         <v-col class="mt-7" cols="8">
           <v-list-item-title class="customerTitle">{{this.customerName}}</v-list-item-title>
                  <span class="customerDetails">{{this.customerMobile}}</span>
         </v-col> 
         <v-col class="mt-8" cols="1">
          <v-btn @click="$router.go(-1)" fab dark class="" color="#434242" elevation="0" small>
                    <v-icon size="15" color="#ffffff" dark>$xMarkIcon</v-icon>
                  </v-btn>
         </v-col>  
       </v-row>

      <v-row no-gutters>
         <v-col class="mt-4 ml-6" cols="3">
          <v-icon size="20" color="#ffffff" dark>$houseNoIcon</v-icon>
          <span class="locationInfo ml-3">{{this.customerHouseNo}}</span>
         </v-col> 
         <v-col class="mt-4 ml-4" cols="7">
          <v-icon size="20" color="#ffffff" dark>$locationIcon</v-icon>
          <span class="locationInfo ml-3">{{this.communityName}}</span>
         </v-col>  
       </v-row>
    </div>
</template>
<script>
import customerServices from '@/services/customerServices'
import router from '@/router'
export default {
    data () {
      return {
        customerGender: '',
        customerName: '',
        customerMobile: '',
        customerHouseNo: '',
        communityName: ''

      }
    },
    async mounted () {
      const customerInfo = (await customerServices.viewCustomer(this.$route.params.customerId)).data;
      this.customerGender = customerInfo.viewCustomer.customerBasicInfo.customerGender
      this.customerName = customerInfo.viewCustomer.customerBasicInfo.customerName
      this.customerMobile = customerInfo.viewCustomer.customerBasicInfo.customerMobile
      this.communityName = customerInfo.viewCustomer.customerCommunityInfo.customerCommunityId.communityName
      this.customerHouseNo = customerInfo.viewCustomer.customerCommunityInfo.customerHouseNo
    },
    methods: {
      toListCustomer () {
        this.dialog = false,
        router.push('customers')
      }
    }
}
</script>
<style scoped>
.locationInfo {
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 14px;
line-height: 34px;
color: #C3C2C2;
}
.customerTitle {
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 18px;
color: #FFFFFF;
}
.customerDetails {
font-family: Lato;
font-style: regular;
font-weight: 800;
font-size: 12px;
line-height: 15px;
color: #C7C6C6;
}
</style>