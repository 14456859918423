<template>
    <div>
        <CircularProgress v-if="loader"/>
        <v-row v-if="!isPlaceholer" no-gutters>
            <v-col cols="9">
                <div class="mt-5">
                    <span class="mt-5 ml-3 mb-2 sectionTitle">{{this.sectionTitle}}</span>
                </div>
            </v-col>
            <v-col cols="3">
                <div class="mt-1 ml-3">
                    <v-icon size="18" class="mr-1 mb-2"> $rupeeIcon2 </v-icon>
                    <span class="totalAmount">{{orderBillAmount}}</span>
                </div>
            </v-col>
        </v-row>

        <v-row v-if="!isPlaceholer" no-gutters>
            <v-col cols="12">
                <div class="">
                     <span class="ml-3 mb-2 orderSource">Source</span>
                    <span class="ml-1 mb-2 orderSource">: {{this.orderSource}}</span>
                    <v-radio-group v-if="isOrderEditable || isOrderNew" v-model="orderSource" class="mt-2 ml-3 ma-0 mb-2 pa-0" row >
                        <v-radio label="Telegram" dark color="#4B9C6D" value="telegram"></v-radio>
                        <v-radio label="WhatsApp" dark color="#4B9C6D" value="whatsApp"></v-radio>
                        <v-radio label="Others" dark color="#4B9C6D" value="others"></v-radio>
                    </v-radio-group>
                </div>
            </v-col>
        </v-row>

        <div class="mt-8" v-if="isPlaceholer" >
            <v-col cols="12">
                <v-card class="d-flex justify-center mr-2 mt-2" color="#373535" dark elevation="0">
                    <v-list color="#373535">
                    <span class="d-flex justify-center">
                    <v-icon class="ml-2 mt-5" size="90"> $notAvailableIcon </v-icon>
                    </span>
                    <span class="d-flex justify-center mt-5 cardTitle">Catalog will be made available soon</span>
                    <span class="d-flex justify-center cardTitle">Try again later</span>
                    </v-list>
                </v-card>
            </v-col>
        </div>

     <div v-if="!isPlaceholer">
       <v-slide-group class="pl-4" center-active>
         <v-slide-item v-for="(category,index) in categories" :key="index" v-slot="{ active, toggle }">
           <div :color="active ? 'primary' : 'grey lighten-1'" class="ma-4 ml-0" @click="toggle">
             <v-btn @click="selectTab(index)" :class="activeCategory===index?'btnActive':'btnInactive'" class="mt-4 v-tab" small color="#434242" block dark elevation="0">{{category}}</v-btn>
           </div>
         </v-slide-item>
       </v-slide-group>

       <div :key="index" v-for="(category,index) in categories">
         <div v-if="activeCategory === index">
            <div :key="productItem.productId" v-for="productItem in customerCartList">
                <v-card class="mt-2 mr-3 ml-3" color="#434242" dark elevation="0" v-if="category === productCategory(productItem.productId)">
                <v-row no-gutters>
                <v-col class="mt-3 mr-2" cols="2">
                  <v-img :src="$globalconfig.productImgPath+'/'+productImage(productItem.productId)"></v-img>
                </v-col>

                <v-col class="mt-3" cols="5">
                    <span class="productTitle">{{productName(productItem.productId)}}</span> <br>
                    <span class="productDescription">{{productDescription(productItem.productId)}}</span> <br>
                    <span class="productSubtitle"><v-icon size="8" class=""> $rupeeIcon2 </v-icon>{{productPrice(productItem.productId)}} / {{productBaseQuantity(productItem.productId)}}   |   </span>
                    <span class="productSubtitle mb-2"> Total: <v-icon size="8" class=""> $rupeeIcon2 </v-icon> {{productItem.productTotalBill}}</span>
                </v-col>
                <v-col class="mt-6" cols="1">
                        <v-btn v-if="(isOrderEditable || isOrderNew) && productItem.orderQuantity !=0" @click="reduceQuantity(productItem.productId)" fab dark color="#F2BA55" elevation="0" x-small>
                            <v-icon color="#ffffff" dark> mdi-minus-thick</v-icon>
                        </v-btn>
                </v-col>

                <v-col class="ml-2 mr-7 mt-7" cols="1">
                    <!-- <span class="quantityNo">{{productItem.orderQuantity}}</span> -->
                    <v-btn class="quantityNo" dark color="#373535" elevation="0" small>
                            {{productItem.orderQuantity}}
                        </v-btn>
                </v-col>

                <v-col class="mt-6" cols="1">
                    <v-btn v-if="isOrderEditable || isOrderNew" @click="addQuantity(productItem.productId)" fab dark color="#4B9C6D" elevation="0" x-small>
                        <v-icon color="#ffffff" dark> mdi-plus-thick</v-icon>
                    </v-btn>
                </v-col>

                </v-row>
                </v-card>
            </div>
          </div>
        </div>

        <div class="ml-3 mr-3 mt-3 mb-2">
            <v-textarea  v-model="orderNote" class="ma-0 pa-0" background-color="#f4f8f7" color="#4B9C6D" v-if="isOrderNew || isOrderEditable" solo name="input-7-4" label="Note" :counter="200" :rules="[v => (v || '' ).length <= 200 || 'Note must be 200 characters or less']"></v-textarea>
            <div v-if="isOrderOverview && orderNote !=''"><span class="listTitle">Note : </span><span class="listContent mb-2">{{this.orderNote}}</span></div>
        </div>

        <v-row no-gutters>
        <v-col cols="6">
            <div class="ml-4 mb-4">
                <v-btn @click="updateOrderStatus('cancelled')" v-if="isOrderOverview || isOrderEditable" outlined class="cancelBtn" block normal dark elevation="0">
                    Cancel Order
                </v-btn>
            </div>
        </v-col>
        <v-col cols="6">
            <div class="ml-4 mr-4 mb-4">
                <v-btn v-if="isOrderNew && orderBillAmount !=0" @click="createOrder()" class="addToBasketBtn" block normal text dark elevation="0">
                    <v-icon left class=""> $cartBasketIcon</v-icon> Add to Basket </v-btn>
                <v-btn v-if="isOrderEditable && orderBillAmount !=0" @click="updateOrder()" class="updateBasketBtn" block normal text dark elevation="0">
                    <v-icon left class=""> $cartBasketIcon</v-icon> Update Basket </v-btn>
                <v-btn @click="updateOrderStatus('delivered')" v-if="isOrderOverview" class="addToBasketBtn" block normal text dark elevation="0">
                    Delivered
                </v-btn>
            </div>
        </v-col>
        </v-row>
     </div>
    </div>
</template>
<script>
// import router from '@/router'
import customerServices from '@/services/customerServices'
import catalogServices from '@/services/catalogServices'
import orderServices from '@/services/orderServices'
import CircularProgress from '../../loaders/CircularProgress.vue'
  export default {
    name: 'viewCustomerRegularOrder',
    components: {
        CircularProgress
    },
    data () {
      return {
        loader: false,
        isOrderEditable: false,
        isOrderNew: false,
        isPlaceholer: false,
        isOrderOverview: false,
        priceList: '', // active price list or the price list used to create the order.
        customerCartList: '', // cart list that is used to render the items. will be consolidated with active price list if available
        activeOrder: false, // if active order available then true else false
        activePrice: false, // if active price available then true else false
        sectionTitle: '',
        orderId: '',
        priceId: '',
        customerId: '',
        orderSource: "telegram",
        orderNote: '',
        categories: [],
        activeCategory: 0
      }
    },
    async mounted () {
        this.fetchCustomerOrder()
    },
    methods: {
    async fetchCustomerOrder () {
        this.loader = true
        this.customerId = this.$route.params.customerId

        const activeCustomerOrder = (await customerServices.viewCustomerActiveOrder(this.customerId, 'replacement')).data;
        const getActivePrice = (await catalogServices.getActicePriceList()).data;
        this.activeOrder = activeCustomerOrder.orderAvailable
        this.activePrice = getActivePrice.activePrice
        if (this.activeOrder) {
            this.orderId = activeCustomerOrder.activeOrder._id
        }


        // BINDING THE PRICE LIST TO "priceList"
        if (this.activePrice) {
            // active price available
            this.priceList = getActivePrice.priceList.productList
            this.priceId = getActivePrice.priceList._id
        } else if (!this.activePrice && this.activeOrder) {
            // active price not available, so fetch price list from active order
            const priceList = (await catalogServices.getPriceList(activeCustomerOrder.activeOrder.priceId)).data;
            this.priceList = priceList.priceList.productList
            this.priceId = priceList.priceList._id
        } else {
            // no active price and no active order
            this.priceList = []
        }
        let categories = []
        this.priceList.map((item)=>{
          categories.push(item.productId.productCategory);
        })
        this.categories = categories.filter((v, i, a) => a.indexOf(v) === i);

        // BINDING THE ORDER LIST TO "customerCartList"

        if (this.activeOrder) {
            // to check if the active order exist, if so we fetch cart list and add it to customerCartList
            this.customerCartList = activeCustomerOrder.activeOrder.cartList
            for(let i=0; i<this.customerCartList.length; i++){
              this.customerCartList[i]['productId'] = this.customerCartList[i]['productId']['_id']
            }
            this.orderSource = activeCustomerOrder.activeOrder.orderSource
            this.orderNote = activeCustomerOrder.activeOrder.orderNote
        } else {
            // no active order exist
            this.customerCartList = []
        }

        // to identify the state of order
        this.orderState ()

        // to generate the customerCartList if the customer order is new or if its editable
        this.appendActivePriceList ()

        this.loader = false
    },
    selectTab(index) {
      this.activeCategory = index
    },
    orderState () {
    if (this.activeOrder == true && this.activePrice == true) {
        this.isOrderEditable = true
        this.sectionTitle = 'Edit Replacement Order'
    } else if (this.activeOrder == true && this.activePrice == false) {
        this.isOrderOverview = true
        this.sectionTitle = 'Replacement Order Overview'
    } else if (this.activeOrder == false && this.activePrice == true) {
        this.isOrderNew = true
        this.sectionTitle = 'Create a Replacement Order'
    } else {
        this.isPlaceholer = true
    }
    },
    appendActivePriceList () {
        // if order is editable then we append priceList with cartList else we only render items in cartList
        if (this.isOrderEditable == true) {
            // to compare active price list with cart list.
            for (let i=0; i<this.priceList.length; i++) {
                const productExists = this.customerCartList.some(productItem => productItem.productId === this.priceList[i].productId._id)
                if(productExists){
                continue
                } else {
                    // add new items from priceList to customerCartList
                    const newItem = {
                        productId: this.priceList[i].productId._id,
                        orderQuantity: '0',
                        productTotalBill: '0',
                    }
                    this.customerCartList.push(newItem)
                }
            }
        } else if (this.isOrderNew) {
            // there is no active order, so we create customerCartList with item from priceList
            this.customerCartList = []
            for (let i=0; i<this.priceList.length; i++) {
                const newItem = {
                    productId: this.priceList[i].productId._id,
                    orderQuantity: '0',
                    productTotalBill: '0',
                }
                this.customerCartList.push(newItem)
            }
        }
    },
    productName (productId) {
        var productName = this.priceList.filter(obj=>obj.productId._id === productId);
        if (productName.length == 0) {
            return 'error'
        }
        return productName[0].productId.productName
    },
    productDescription (productId) {
        var productDescription = this.priceList.filter(obj=>obj.productId._id === productId);
        if (productDescription.length == 0) {
            return 'error'
        }
        return productDescription[0].productId.productDescription
    },
    productCategory (productId) {
        var productCategory = this.priceList.filter(obj=>obj.productId._id === productId);
        if (productCategory.length == 0) {
            return 'error'
        }
        return productCategory[0].productId.productCategory
    },
    productBaseQuantity (productId) {
        var productBaseQuantity = this.priceList.filter(obj=>obj.productId._id === productId);
        if (productBaseQuantity.length == 0) {
            return 'error'
        }
        return productBaseQuantity[0].productBaseQuantity
    },
    productImage (productId) {
        var productImage = this.priceList.filter(obj=>obj.productId._id === productId);
        if (productImage.length == 0) {
            return 'error'
        }
        return productImage[0].productId.productImageFileName
    },
    productPrice (productId) {
        var productPrice = this.priceList.filter(obj=>obj.productId._id === productId);
        if (productPrice.length == 0) {
            return 'error'
        }
        return productPrice[0].productPrice
    },
    addQuantity (productId) {
    const productPrice = this.productPrice(productId)
    const productBaseQuantity = this.productBaseQuantity(productId)
    const objIndex = this.customerCartList.findIndex((obj => obj.productId == productId))

    this.customerCartList[objIndex].orderQuantity = parseInt(this.customerCartList[objIndex].orderQuantity) + parseInt(1)
    this.customerCartList[objIndex].productTotalBill = productPrice * this.customerCartList[objIndex].orderQuantity
    this.customerCartList[objIndex].productBaseQuantity = productBaseQuantity

    },
    reduceQuantity (productId) {
    const productPrice = this.productPrice(productId)
    const productBaseQuantity = this.productBaseQuantity(productId)
    const objIndex = this.customerCartList.findIndex((obj => obj.productId == productId))

    this.customerCartList[objIndex].orderQuantity = parseInt(this.customerCartList[objIndex].orderQuantity) - parseInt(1)
    this.customerCartList[objIndex].productTotalBill = productPrice * this.customerCartList[objIndex].orderQuantity
    this.customerCartList[objIndex].productBaseQuantity = productBaseQuantity
    },
    createCartItems () {
        // selecting the products that have quantity
        const basketItems = {
            cartList: []
        }
        for (let i=0; i<this.customerCartList.length; i++) {
            if(this.customerCartList[i].orderQuantity != 0) {
                basketItems.cartList.push(this.customerCartList[i])
            }
        }
        return basketItems
    },
    async createOrder () {
        this.loader = true
        const orderInfo = {
            orderType: 'replacement',
            priceId: this.priceId,
            customerId: this.customerId,
            orderSource: this.orderSource,
            orderNote: this.orderNote,
            orderBillAmount: this.orderBillAmount,
            cartList: this.createCartItems().cartList
        }
        try {
            await orderServices.createOrder(orderInfo).then(result => {
                this.customerCartList = result.data.activeOrder.cartList
                this.orderId = result.data.activeOrder._id
                this.orderStatus = result.data.activeOrder.orderStatus
                this.activeOrder = true, this.isOrderEditable = true, this.isOrderNew = false
            })
        } catch (err) {
            console.log(err)
        }
        this.activeOrder = true,
        this.isOrderNew = false
        this.orderState()
        this.appendActivePriceList ()
        this.loader = false
    },
    async updateOrder () {
        this.loader = true
        const orderInfo = {
            orderSource: this.orderSource,
            orderNote: this.orderNote,
            orderBillAmount: this.orderBillAmount,
            cartList: this.createCartItems().cartList
        }
        // making the api call to update the basket
        try {
          await orderServices.updateOrder(this.orderId, orderInfo).then(result => {
              this.customerCartList = result.data.activeOrder.cartList
              this.orderStatus = result.data.activeOrder.orderStatus
            }
          )
        } catch (err) {
          console.log(err)
        }
        // updating the customer cart list and then appending to the active price list
        this.appendActivePriceList ()
        this.loader = false
    },
    async updateOrderStatus (orderStatus) {
        this.loader = true
        const orderInfo = {
            orderStatus: orderStatus
        }
        // making the api call to update the basket
        try {
          await orderServices.updateOrderStatus(this.orderId, orderInfo).then(result => {
              if (result.data.activeOrder.orderStatus == "delivered") {
                  this.activeOrder = false,
                  this.isOrderNew = false
                  this.isPlaceholer = true
                  this.orderState()
              } else if (result.data.activeOrder.orderStatus == "cancelled") {
                  if (this.activePrice) {
                      this.isOrderNew = true,
                      this.activeOrder = false,
                      this.isOrderEditable = false
                      this.orderState()
                      this.appendActivePriceList ()
                  } else {
                      this.isOrderNew = false,
                      this.activeOrder = false,
                      this.orderState()
                  }
              }
            }
          )
        } catch (err) {
          console.log(err)
        }
        // updating the customer cart list and then appending to the active price list
        this.appendActivePriceList ()
        this.loader = false
    }
    },
    computed: {
        orderBillAmount() {
            var billTotal = 0
            for (let i=0; i<this.customerCartList.length; i++) {
                billTotal = billTotal+parseInt(this.customerCartList[i].productTotalBill)
            }
            return billTotal
        }
  }
  }
</script>
<style scoped>
.listTitle {
font-family: Lato;
font-style: regular;
font-weight: 700;
font-size: 14px;
line-height: 0px;
color: #C7C6C6;
}
.listContent {
margin-top: 20px;
font-family: Lato;
font-style: regular;
font-weight: 500;
font-size: 14px;
line-height: 0px;
color: #C7C6C6;
}
.cardTitle{
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 12px;
color: #C7C6C6;
}
.productTitle {
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 18px;
color: #FFFFFF;
}
.notAvailableText {
font-family: Lato;
font-style: regular;
font-weight: 700;
font-size: 14px;
color: #9B9A9A;
}
.productDescription {
font-family: Lato;
font-style: regular;
font-weight: 700;
font-size: 15px;
color: #C7C6C6;
}
.productSubtitle {
font-family: Lato;
font-style: regular;
font-weight: 700;
font-size: 10px;
color: #C7C6C6;
}
.quantityNo {
font-family: Montserrat;
font-style: normal;
font-weight: 900;
font-size: 18px;
color: #FFFFFF;
}
.sectionTitle {
font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 26px;
color: rgba(255, 255, 255, 0.5);
}
.orderSource {
font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 26px;
text-transform: capitalize;
color: rgba(255, 255, 255, 0.5);
}
.addToBasketBtn {
text-transform: unset !important;
background: #4B9C6D;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 29px;
color: #FFFFFF;
}
.updateBasketBtn {
text-transform: unset !important;
background: #F2BA55;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 29px;
color: #FFFFFF;
}
.cancelBtn {
text-transform: unset !important;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 29px;
color: #E14B4B;
}
.totalAmount {
font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 59px;
color: rgba(255, 255, 255, 0.5);
}
.v-tab {
  padding: 20px !important;
  border-radius: 0 !important;
}
.v-tab.btnActive {
  background: #7a7a7a !important;
  color: #fff !important;
  border-bottom: 2px solid white !important;
}
</style>

<!--

ACTIVEORDER EXIST             | ACTIVE PRICE EXIST |               = EDIT = TRUE    |   CREATE = FALSE   |    PLACEHOLDER = FALSE   | ORDERVIEW = FALSE
ACTIVEORDER EXIST             | ACTIVE PRICE DOES NOT EXIST |      = EDIT = FALSE   |   CREATE = FALSE   |    PLACEHOLDER = FALSE   | ORDERVIEW = TRUE
ACTIVEORDER DOES NOT EXIST    | ACTIVE PRICE EXIST                 = CREATE = TRUE  |   EDIT = FALSE     |    PLACEHOLDER = FALSE   | ORDERVIEW = FALSE
ACTIVEORDER DOES NOT EXIST    | ACTIVE PRICE DOES NOT EXIST        = CREATE = FALSE |   EDIT = FALSE     |    PLACEHOLDER = TRUE    | ORDERVIEW = FALSE

-->
