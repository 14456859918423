import Api from '@/services/Api'

export default {
  getActicePriceList() {
    return Api().get('/api/admin/getActicePriceList')
  },
  getPriceList(priceId) {
    return Api().get(`/api/admin/getPriceList/${priceId}`)
  },
  getCatalogList(partner) {
    return Api().get(`/api/partner/getPartnerMenuFiles/${partner.partnerId}`)
  },
}
