<template>
  <v-row justify="center">
    <v-dialog scrollable v-model="dialog" persistent max-width="350">
    <CircularProgress v-if="loader"/>
      <v-card class="mt-2" dark max-width="400" elevation="0">
        <v-list color="#ffffff">
            <v-list-item>
                <v-icon size="30" class="mr-3"> $boxActiveIcon </v-icon>
                <v-list-item-content>
                    <v-list-item-title class="customerName">{{orderDeatils.customerName}}</v-list-item-title>
                    <span class="mt-1 capitaliseText"><v-chip small :color="checkPaymentStatus.statusColor">{{checkPaymentStatus.status}}</v-chip></span>
                </v-list-item-content>
 
                <v-list-item-content class="text-right">
                    <span class="houseText">House</span>
                    <span class="houseNo">{{orderDeatils.customerHouseNo}}</span>
                </v-list-item-content>
            </v-list-item>
            <v-divider light class="ml-3 mr-3"></v-divider>
            <div class="ml-2 mr-2 mt-3 mb-3">
                <v-expansion-panels flat>
                    <v-expansion-panel elevation="0">
                        <v-expansion-panel-header elevation="0" color="#ffffff"><span class="listTitle">Order No. #1045</span>
                            <template v-slot:actions>
                                <v-icon color="#434242">$expand</v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content color="#ffffff">
                            <div class="mt-2"><span class="listTitle">Order Type.</span>  <br><span class="capitaliseText">{{orderDeatils.orderType}}</span>  <br> </div>
                            <div class="mt-2"><span class="listTitle">Order Date</span>  <br><span class="listContent">{{moment(orderDeatils.orderDate).format("dddd, MMMM Do YYYY, h:mm a")}}</span>  <br></div>
                            <div class="mt-2"><span class="listTitle">Order Source</span>  <br><span class="capitaliseText">{{orderDeatils.orderSource}}</span>  <br> </div>
                            <div class="mt-2"><span class="listTitle">Order Items</span>  <br><span class="listContent">{{orderDeatils.cartItemsList}}</span> <br> </div>
                            <div class="mt-2"><span class="listTitle">Items Count</span>  <br><span class="listContent">{{orderDeatils.cartItemsCount}}</span>  <br> </div>
                            <div class="mt-2"><span class="listTitle">Bill Amount</span>  <br><span class="listContent">{{orderDeatils.orderBillAmount}}</span> <br> </div>  
                            <div class="mt-2"><span class="listTitle">Order Note</span>  <br><span class="listContent">{{orderDeatils.orderNote}}</span> <br> </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
            <div>
                <v-list-item>
                    <v-list-item-content class="ml-3 mr-3">
                        <v-list-item-title class="houseText">Bill Amount</v-list-item-title>
                        <span class="mt-1 capitaliseText"><v-chip small color="#4B9C6D">{{orderDeatils.orderBillAmount}}</v-chip></span>
                    </v-list-item-content>
                </v-list-item>                
            </div>  
            <div>
                <v-list-item>
                    <v-list-item-content class="ml-3 mr-3">
                        <v-list-item-title class="houseText">Received</v-list-item-title>
                        <span class="mt-1 capitaliseText"><v-chip small :color="checkPaymentStatus.statusColor">{{amountReceived}}</v-chip></span>
                    </v-list-item-content>
    
                    <v-list-item-content class="text-right">
                        <span class="houseText">Remaining</span>
                        <span class="mt-1 capitaliseText"><v-chip small color="#434242">{{remainingAmount}}</v-chip></span>
                    </v-list-item-content>
                </v-list-item>                
            </div>
            <v-divider light class="mt-3 ml-12 mr-12"></v-divider> 
             <div class="mt-5 ml-6 mr-6">
                <span class="houseText">Fully Paid</span>
                <v-switch @click="fullyPaid()" v-model="switch1" light color="#4B9C6D"></v-switch>
            </div> 
            <div v-if="switch1 != true"  class="mt-5 ml-6 mr-6">
                <span class="houseText">Select Amount</span>
                <v-slider class="mt-10" color="#434242" min="0" :max="orderDeatils.orderBillAmount" light v-model="amountReceived" :thumb-size="35" thumb-color="#434242" thumb-label="always"></v-slider>
            </div> 
            <div v-if="remainingAmount != 0" class="mt-2 ml-6 mr-6">
                <span class="houseText">Reason</span>
                <v-select class="mt-4" v-model="issueTags" background-color="#ffffff" item-color="#434242" color="#434242" outlined light :items="reasonItems" label="Issues" multiple chips persistent-hint></v-select>
            </div> 
            
            <div v-if="remainingAmount != 0" class="mt-2 ml-6 mr-6">
                <span class="houseText">Note</span>
                <v-textarea v-model="paymentNote" flat light class="mt-4 ma-0 pa-0" outlined background-color="#ffffff" color="#434242" solo name="input-7-4" label="Note" :counter="200" :rules="[v => (v || '' ).length <= 200 || 'Note must be 200 characters or less']"></v-textarea>
            </div> 

             <div>
                <v-row no-gutters>
                <v-col cols="6">
                    <div class="mt-4 ml-6 mr-5 mb-4">
                        <v-btn @click="cancelPayment" outlined class="cancelBtn" block normal dark elevation="0"> Cancel</v-btn>
                    </div>
                </v-col>
                <v-col cols="6">
                    <div class="mt-4 ml-6 mr-5 mb-4">
                        <v-btn @click="savePayment" class="saveBtn" block normal text dark elevation="0"> Save</v-btn>
                    </div>
                </v-col>
                </v-row>
            </div>  

        </v-list>
    </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import moment from 'moment'
import orderServices from '@/services/orderServices'
import CircularProgress from '../loaders/CircularProgress.vue'
  export default {
    components: {
        CircularProgress
    },
    props: {
        orderId: String
    },
    data () {
      return {
        loader: false,
        moment: moment,
        sliderStatus: '',
        switch1: false,
        orderDeatils: '',
        paymentStatus: '',
        amountReceived: '',
        paymentNote: '',
        issueTags: [],
        dialog: true,
         reasonItems: [
          'None', 'Quality', 'Missing Items', 'Customer did not pay full amount', 'Wrong item', 'Wrong Order by Customer', 'Wrong Order by Partner', 'Others'],
      }
    },
    async mounted () {
        this.loader = true
        const orderInfo = {
            "orderId": this.orderId
        }
        const orderDetailResponse = (await orderServices.viewCustomerOrderDetails(orderInfo)).data;
        this.orderDeatils = orderDetailResponse.customerOrderDetails[0]
        this.amountReceived = orderDetailResponse.customerOrderDetails[0].orderPayment.amountReceived
        if (this.amountReceived < this.orderDeatils.orderBillAmount ) {
            this.switch1 = false
        } else if (this.amountReceived == this.orderDeatils.orderBillAmount) {
            this.switch1 = true
        }
        this.issueTags = orderDetailResponse.customerOrderDetails[0].orderPayment.issueTags
        this.paymentNote = orderDetailResponse.customerOrderDetails[0].orderPayment.paymentNote
        this.loader = false

    },
    methods: {
        async savePayment () {
            this.loader = true
            const paymentInfo = {
                orderId: this.orderId,
                orderPayment: {
                    status: this.checkPaymentStatus.status,
                    amountReceived: this.amountReceived,
                    issueTags: this.issueTags,
                    paymentNote: this.paymentNote
                }
            }
            try {
                await orderServices.updateOrderPayment(paymentInfo).then(result => {
                    if (result.data.paymentUpdated == true) {
                        this.loader = false
                        this.dialog = false
                        this.$emit('close-payment')
                    }
                });    
            } catch (err) {
                console.log(err)
            }
             //this.dialog = false
        },
        fullyPaid() {
            if (this.switch1 == true) {
                this.amountReceived = this.orderDeatils.orderBillAmount
            } else if (this.switch1 == false) {
                this.amountReceived = 0
            }
        },
        cancelPayment () {
            this.$emit('close-payment')
            this.dialog = false
        }
    },
    computed: {
        remainingAmount () {
            var remainingTotal = 0
            remainingTotal = parseInt(this.orderDeatils.orderBillAmount) - parseInt(this.amountReceived)
            return remainingTotal
        },
        checkPaymentStatus () {
            var status = {
                status: "",
                statusColor: ""
            }
            if (this.amountReceived < this.orderDeatils.orderBillAmount && this.amountReceived !=0) {
                status.status = 'Partially Paid'
                status.statusColor = '#F2BA55'
            } else if (this.amountReceived == 0) {
                status.status = 'Not Paid'
                status.statusColor = '#E14B4B'
            } else if (this.amountReceived == this.orderDeatils.orderBillAmount) {
                status.status = 'Paid'
                status.statusColor = '#4B9C6D'
            }
            return status
        }
    }
  }
</script>
<style scoped>
.capitaliseText {
font-family: Lato;
font-style: regular;
font-weight: 500;
font-size: 14px;
line-height: 0px;
color: #434242;
text-transform: capitalize;
}
.customerName{
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 18px;
color: #434242;
}
.listTitle {
font-family: Lato;
font-style: regular;
font-weight: 700;
font-size: 14px;
line-height: 0px;
color: #434242;
}
.listContent {
margin-top: 20px;
font-family: Lato;
font-style: regular;
font-weight: 500;
font-size: 14px;
line-height: 0px;
color: #434242;
}

.houseNo {
font-family: Montserrat;
font-style: normal;
font-weight: 900;
font-size: 24px;
color: #434242;
}

.houseText {
font-family: Montserrat;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #434242;
}

.saveBtn {
text-transform: unset !important;
background: #4B9C6D;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 29px;
color: #ffffff;
}
.cancelBtn {
text-transform: unset !important;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 29px;
color: #E14B4B;
}
</style>