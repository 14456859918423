<template>

    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">

     <v-card color="#373535" elevation="0">

      <ViewCustomerInfo />

       <v-divider class="ml-5 mr-5 mt-5 dividerLine"></v-divider>  

       <v-row no-gutters>
         <v-col cols="5" class="ml-4">
           <v-btn @click="orderType('regular')" class="mt-4 btnActive" small :color="regularBtn" block dark elevation="0">Regular Order</v-btn>
          
           <!-- <v-btn class="mt-4 ml-3 btnText" small text dark elevation="0">Profile</v-btn> -->
         </v-col>
         <v-col cols="5" class="ml-4">
           <v-btn @click="orderType('replacement')" class="mt-4 ml-3 btnText" :color="replacementBtn" block small dark elevation="0">Replacement Order</v-btn>
           <!-- <v-btn class="mt-4 ml-3 btnText" small text dark elevation="0">Profile</v-btn> -->
         </v-col>

         <!-- <v-col cols="5" class="ml-4">
           <v-btn @click="orderType('past')" class="mt-4 btnText" color="#434242" block small dark elevation="0">Open Orders</v-btn>
         </v-col> -->
         <v-col cols="11" class="">
           <v-btn @click="orderType('past')" class="mt-4 ml-3 btnText" :color="pastBtn" block small dark elevation="0">Past Orders</v-btn>
         </v-col>
       </v-row>
       

      <ViewCustomerRegularOrder v-if="this.regularOrder" />
      <ViewCustomerReplacementOrder v-if="this.replacementOrder" />
      <ViewCustomerPastOrders v-if="this.pastOrder" />

    </v-card>

    </v-dialog>

</template>
<script>
import ViewCustomerInfo from '../viewCustomer/ViewCustomerInfo.vue'
import ViewCustomerRegularOrder from '../viewCustomer/ViewCustomerRegularOrder.vue'
import ViewCustomerReplacementOrder from '../viewCustomer/ViewCustomerReplacementOrder.vue'
import ViewCustomerPastOrders from './pastOrders/ViewPastOrdersMain.vue'
  export default {
    name: 'ViewCustomerMain',
    components: {
      ViewCustomerInfo,
      ViewCustomerRegularOrder,
      ViewCustomerPastOrders,
      ViewCustomerReplacementOrder
    },
    data () {
      return {
        dialog: true, 
        regularOrder: true,
        replacementOrder: false,
        pastOrder: false,
        regularBtn: "#4B9C6D",
        replacementBtn: "#434242",
        pastBtn: "#434242"
      }
    }, 
    methods: {
      orderType (orderType) {
        if (orderType == "regular") {
          this.regularOrder = true,
          this.replacementOrder = false,
          this.pastOrder = false
          this.regularBtn = "#4B9C6D",
          this.replacementBtn = "#434242",
          this.pastBtn = "#434242"
        } else if (orderType =="replacement") {
          this.replacementOrder = true,
          this.regularOrder = false,
          this.pastOrder = false
          this.regularBtn = "#434242",
          this.replacementBtn = "#4B9C6D",
          this.pastBtn = "#434242"
        } else if (orderType =="past") {
          this.regularOrder = false,
          this.replacementOrder = false,
          this.pastOrder = true,
          this.regularBtn = "#434242",
          this.replacementBtn = "#434242",
          this.pastBtn = "#4B9C6D"
        }
      }
    }
  }
</script>
<style scoped>
.btnActive {
text-transform: unset !important;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 26px;
color:#ffffff;
}
.btnText {
text-transform: unset !important;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 26px;
color:#ffffff;
}
.dividerLine{
color: rgba(255, 255, 255, 0.5);
}
</style>